<!-- 拒绝原因 -->
<template>
  <div>
    <el-dialog
      width="40%"
      center
      :visible="visible"
      :lock-scroll="false"
      :destroy-on-close="true"
      custom-class="ele-dialog-form"
      :title="isUpdate?'取消原因':'取消原因'"
      @update:visible="updateVisible">
      <el-form
        ref="form"
        :model="form"
        :rules="rules"
        label-width="140px">
        <div>
          <el-row :gutter="15">
            <el-col :span="12">
              <el-form-item label="订单号/案件号：" style="margin-bottom: 0!important;">
                <span style="margin-right: 10px;">{{form.order_id}}</span>
                <span>{{form.case_number}}</span>
              </el-form-item>
            </el-col>
            <el-col :span="12">
              <!--<el-form-item label="客户名称：" prop=""  style="margin-bottom: 0!important;">-->
              <!--  <span>柳新吉</span>-->
              <!--</el-form-item>-->
            </el-col>
          </el-row>

          <el-row :gutter="15">
            <el-col :span="12">
              <el-form-item label="客户电话：" prop=""  style="margin-bottom: 0!important;">
                <div style="display: flex;align-items: center;">
                  <span>{{form.buyer_phone}}</span>
                  <img src="@/assets/images/home/dianhua.png" alt="" style="width: 18px;height: 18px;margin-left: 10px;"  @click="tel(form.buyer_phone,'其他')">
                </div>
              </el-form-item>
            </el-col>
            <el-col :span="12">
              <el-form-item label="救援类型："  style="margin-bottom: 0!important;">
                <span>{{form.items_name}}</span>
              </el-form-item>
            </el-col>
          </el-row>

          <el-row :gutter="15">
            <el-col :span="12">
              <el-form-item label="客户："  style="margin-bottom: 0!important;">
                <span>{{form.owners}}</span>
              </el-form-item>
            </el-col>
            <el-col :span="12">
              <el-form-item label="客户电话：" prop=""  style="margin-bottom: 0!important;">
                <div style="display: flex;align-items: center;">
                  <span>{{form.owners_phone}}</span>
                  <img src="@/assets/images/home/dianhua.png" alt="" style="width: 18px;height: 18px;margin-left: 10px;" @click="tel(form.owners_phone,'客户')">
                </div>
              </el-form-item>
            </el-col>
          </el-row>
          <el-row :gutter="15">
            <el-col :span="24">
              <el-form-item label="救援地：" style="margin-bottom: 0!important;">
                <span>{{form.destination}}</span>
              </el-form-item>
            </el-col>
          </el-row>

          <el-row :gutter="15">
            <el-col :span="24">
              <el-form-item label="拒绝说明：" prop="reject_reason">
                <el-input
                  type="textarea"
                  :autosize="{ minRows: 3, maxRows: 5}"
                  placeholder="请输入内容"
                  v-model="form.reject_reason">
                </el-input>
              </el-form-item>
            </el-col>
          </el-row>


        </div>

      </el-form>

      <div slot="footer">
        <el-button
          @click="updateVisible(false)">取消
        </el-button>
        <el-button
          style="margin-left: 20px;"
          type="primary"
          :loading="loading"
          @click="save('form')"
          v-auths="[`${$config.uniquePrefix}orderModule:list:cancel`]">确认
        </el-button>
      </div>
    </el-dialog>
  </div>
</template>

<script>
//拖动排序
import draggable from 'vuedraggable'
import {delay_joinreject} from "@/api/orderModule";
import {get_tel_no,} from '@/api/phone'

export default {
  props: {
    // 弹窗是否打开
    visible: Boolean,
    // 修改回显的数据
    data: Object
  },
  components: {
    draggable
  },
  data() {
    return {
      // 表单数据
      form: Object.assign({}, this.data),
      //验证规则
      rules:{
        reject_reason:[
          { required: true, message: '请输入内容', trigger: 'blur' },
        ],
      },
      // 提交状态
      loading: false,
      // 是否是修改
      isUpdate: false,

      dialogImageUrl: '',
      dialogVisible: false

    };
  },
  watch: {
    data() {
      if (this.data) {
        console.log(this.data)
        this.form = Object.assign({}, this.data);
      } else {
        this.form = {};
      }
    }
  },
  mounted() {

  },
  methods: {
    /* 保存编辑 */
    save(formName){
      this.$refs[formName].validate((valid) => {
        let data = {
          reject_reason: this.form.reject_reason
        }
        if (valid) {
          delay_joinreject(this.form.id,data).then(res => {
            console.log(res)
            if (res.code === 200) {
              this.$message.success(res.msg);
              this.$emit('ShowTurndown',false) ;
            } else {
              this.$message.error(res.msg);
            }
          }).catch(e => {
            this.$message.error(e.msg);
          })
        } else {
          return false;
        }
      });
    },

    /* 更新visible */
    updateVisible(value) {
      this.$emit('update:visible', value);
    },

    // 点击拨打电话
    tel(phone,tel_type){
      get_tel_no().then(res => {
        if(res.data.is_exist == 0){

          // 显示拨打电话弹窗
          let data = {
            showPhone: true
          }
          this.$addStorageEvent(1, "showPhone", JSON.stringify(data))
          // 要拨打的手机号
          let dianhua = {
            phone: phone
          }
          this.$addStorageEvent(1, "phone", JSON.stringify(dianhua))

          // 获取 订单号id
          let orderid = {
            orderid: this.data.id,
          }
          this.$addStorageEvent(1, "orderid", JSON.stringify(orderid))

          // 获取呼出类型
          let teltype = {
            tel_type: tel_type
          }
          this.$addStorageEvent(1, "tel_type", JSON.stringify(teltype))
        }else {
          this.$message.error('暂无坐席')
        }
      })
    },


  }
}
</script>

<style lang="scss" scoped>
.hide_box /deep/ .el-upload--picture-card {
  display: none;
}
</style>
